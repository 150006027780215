import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { Box, Grid, Typography } from "@material-ui/core";
import { RenderPlanTable as useStyles } from "./Styles";
import moment from 'moment';
import { CommaFormattedPrice } from "../../utils/Utils";


function RenderPlanTable(props) {
  const { t, currency, lineItems, plan, subtotal, amountSubtotal, total, amountTotal, discountCoupon, discountCoupons, invoice } = props;
  const classes = useStyles(props);

  return (
    <Box component="section" className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={7} sm={8} md={9}>
          <Typography variant="body1" className={classes.title}>{invoice ? t("common:subscription") : t("payment:selected_subscription")}</Typography>
        </Grid>
        <Grid item xs={5} sm={4} md={3}>
          <Typography variant="body1" className={clsx(classes.title, classes.end)}>{t("payment:price")}</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.container}>
        {lineItems ? lineItems.map((lineItem, index) =>
          <Fragment key={index}>
            <Grid item xs={7} sm={8} md={9}>
              <Typography variant="h6" className={classes.body}>{lineItem.description}</Typography>
              {(lineItem.periodStart || lineItem.currentPeriodStart) &&
                <Typography variant="body2">
                  {t('subscription:period')}: {moment(lineItem.periodStart || lineItem.currentPeriodStart).format("ll")}
                  {(lineItem.periodEnd || lineItem.currentPeriodEnd) && (" - " + moment(lineItem.periodEnd || lineItem.currentPeriodEnd).format("ll"))}
                </Typography>
              }
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
              <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{lineItem.currency} {CommaFormattedPrice((lineItem.amount / 100).toFixed(2))}</Typography>
            </Grid>
          </Fragment>
        ) : plan &&
        <Fragment>
          <Grid item xs={7} sm={8} md={9}>
            <Typography variant="h6" className={classes.body}>{plan.stripeProductName} {(plan.stripeNickname !== undefined && plan.stripeNickname !== null && plan.stripeNickname !== "") ? ` - ${plan.stripeNickname}` : ""}</Typography>
          </Grid>
          <Grid item xs={5} sm={4} md={3}>
            <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{plan.currency} {CommaFormattedPrice((plan.amount / 100).toFixed(2))}</Typography>
          </Grid>
        </Fragment>
        }

        {(subtotal || subtotal === 0 || amountSubtotal || amountSubtotal === 0) &&
          <Fragment>
            <Grid item xs={7} sm={8} md={9} className={classes.borderTop}>
              <Typography variant="body1" className={clsx(classes.title, classes.padding, classes.end)}>{t("payment:subtotal")}</Typography>
            </Grid>
            <Grid item xs={5} sm={4} md={3} className={classes.borderTop}>
              <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{currency} {CommaFormattedPrice(((subtotal || amountSubtotal) / 100).toFixed(2))}</Typography>
            </Grid>
          </Fragment>
        }

        {discountCoupon && discountCoupon.valid &&
          <Fragment>
            <Grid item xs={7} sm={8} md={9}>
              <Typography variant="body1" className={classes.padding}>{(discountCoupon.name)} {discountCoupon.percentOff ?
                <Fragment>
                  ({discountCoupon.duration === "forever" ? t("payment:percent_off_forever", { value: discountCoupon.percentOff })
                    : discountCoupon.duration === "once" ? t("payment:percent_off_once", { value: discountCoupon.percentOff })
                      : discountCoupon.repeating === "repeating" && discountCoupon.durationInMonths && t("payment:percent_off_repeat", { value: discountCoupon.percentOff, months: discountCoupon.durationInMonths })
                  })
                </Fragment> : discountCoupon.amountOff &&
                <Fragment>
                  ({discountCoupon.duration === "forever" ? t("payment:amount_off_forever", { currency: currency, value: (discountCoupon.amountOff / 100).toFixed(2) })
                    : discountCoupon.duration === "once" ? t("payment:amount_off_once", { currency: discountCoupon.currency, value: (discountCoupon.amountOff / 100).toFixed(2) })
                      : discountCoupon.repeating === "repeating" && discountCoupon.durationInMonths && t("payment:amount_off_repeat", { currency: discountCoupon.currency, value: (discountCoupon.amountOff / 100).toFixed(2), months: discountCoupon.durationInMonths })
                  })
                </Fragment>
              } </Typography>
            </Grid>
            <Grid item xs={5} sm={4} md={3}>
              <Typography variant="body1" className={clsx(classes.body, classes.end, classes.currency)}>{currency} {(-(discountCoupon.totalAmountOff) / 100).toFixed(2)}</Typography>
            </Grid>
          </Fragment>
        }

        {discountCoupons &&
          (discountCoupons ? discountCoupons.map((coupon, index) =>
            coupon.valid &&
            <Fragment key={index}>
              <Grid item xs={7} sm={8} md={9}>
                <Typography variant="body1" className={classes.padding}>{(coupon.name)} {coupon.percentOff ?
                  <Fragment>
                    ({coupon.duration === "forever" ? t("payment:percent_off_forever", { value: coupon.percentOff })
                      : coupon.duration === "once" ? t("payment:percent_off_once", { value: coupon.percentOff })
                        : coupon.repeating === "repeating" && coupon.durationInMonths && t("payment:percent_off_repeat", { value: coupon.percentOff, months: coupon.durationInMonths })
                    })
                    </Fragment> : coupon.amountOff &&
                  <Fragment>
                    ({coupon.duration === "forever" ? t("payment:amount_off_forever", { currency: currency, value: (coupon.amountOff / 100).toFixed(2) })
                      : coupon.duration === "once" ? t("payment:amount_off_once", { currency: coupon.currency, value: (coupon.amountOff / 100).toFixed(2) })
                        : coupon.repeating === "repeating" && coupon.durationInMonths && t("payment:amount_off_repeat", { currency: coupon.currency, value: (coupon.amountOff / 100).toFixed(2), months: coupon.durationInMonths })
                    })
                    </Fragment>
                } </Typography>
              </Grid>
              <Grid item xs={5} sm={4} md={3}>
                <Typography variant="body1" className={clsx(classes.body, classes.end, classes.currency)}>{currency} {(-(coupon.totalAmountOff) / 100).toFixed(2)}</Typography>
              </Grid>
            </Fragment>
          ) : null
          )
        }

        {(total || total === 0 || amountTotal || amountTotal === 0) &&
          <Fragment>
            <Grid item xs={7} sm={8} md={9} className={classes.border}>
              <Typography variant="body1" className={clsx(classes.title, classes.padding, classes.end)}>{t("payment:amount_due")}</Typography>
            </Grid>
            <Grid item xs={5} sm={4} md={3} className={classes.border}>
              <Typography variant="h6" className={clsx(classes.body, classes.end, classes.currency)}>{currency} {CommaFormattedPrice(((total || amountTotal) / 100).toFixed(2))}</Typography>
            </Grid>
          </Fragment>
        }
      </Grid>
    </Box>
  )
}

export default withTranslation(["common", "payment", "subscription"])(RenderPlanTable);